import React, { Component } from 'react';
import { PageHeader } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import axios from "axios";

import QuizApp from '../components/QuizApp';

export default class Test extends Component {
	state = {
		isLoading: true,
		testData: [],
		dummyData: {},
		testQuestions: [],
		error: ''
	};

	async componentDidMount() {
		Auth.currentAuthenticatedUser().then(response=>{
			// console.log(response.signInUserSession);
			let jwt = response.signInUserSession.idToken.jwtToken;
			sessionStorage.setItem('myJwt', jwt);

			this.getTestData();
		});

		this.setState({ isLoading: false });
	}

	getTestData() {
		//console.log("run getTestData");
		const token = sessionStorage.getItem('myJwt');
		// console.log('myJwt', token);
		const apiUrl = 'https://agoxhh63vk.execute-api.us-east-1.amazonaws.com/dev';

		const headers = {
			'Content-Type': 'application/json',
			'Authorization': token
		}
		const data = {};

		axios({
			method: 'POST',
			url: apiUrl + '/start/test/1',
			headers: headers,
			data: data
		})
		.then((response) => {
			//console.log("POST success");
			//console.log(response);
			
			let testData = response.data.data;
			this.setState({
				testData: testData
			});
			sessionStorage.setItem('testData', testData);

			this.initFormatQuestions(testData);
		})
		.catch((error) => {
			console.log("POST error");
			console.log(error.response.data);

			this.setState({
				error: error.response.data.data.public
			});
		});
	}

	initFormatQuestions(testData) {
		// init formatQuestions - can this be rewritten more efficiently?
		this.setState({ testId: testData.test_id });

		let testQuestions = this.formatQuestions(testData);
		//console.log("initFormatQuestions testQuestions", testQuestions);
		this.setState({ testQuestions: testQuestions });
	}

	formatQuestions(testData) {
		// format test questions - REWRITE THIS later to use original json formatting
		const questions = testData.questions;
		let formatted = [];

		//console.log("formatQuestions", questions.length);
		for (var i = 0; i < questions.length; i++) {
			// console.log("formatQuestions question", questions[i]);
			const question = questions[i];

			let formattedQuestion = {
				id: i+1, // id within this test
				questionId: question.questionId, // id within db
				question: '',
				imageUrl: '',
				correct_answer: 'a' // OUTDATED - remove this when test results handling is rewritten
			};

			if (question.imageUrl) {
				formattedQuestion.imageUrl = question.imageUrl;
				formattedQuestion.question += question.caption;
			}
			formattedQuestion.question += question.questionText;

			const options = question.options;
			for (var j = 0; j < options.length; j++) {
				formattedQuestion["answer_"+(j+1)] = options[j].optionText;
				formattedQuestion["answerId_"+(j+1)] = options[j].id;
				formattedQuestion["answerImageUrl_"+(j+1)] = options[j].imageUrl;
				formattedQuestion["answer_"+(j+1)] += options[j].caption;
			}

			// console.log("formattedQuestion", formattedQuestion);
			formatted.push(formattedQuestion);
		}
		// console.log("formatted", formatted);

		return formatted;
	}

	renderUnauthView() {
		return (
			<div className="lander">
				<h1>Unauthorized</h1>
				<p>Please <a href="/login">log in</a> to access this page.</p>
			</div>
		);
	}

	renderAuthView() {
		const testId = this.state.testId || {};
		const testQuestions = this.state.testQuestions || {};
		//console.log("renderAuthView testData", testData);
		// console.log("renderAuthView testQuestions", testQuestions);
		return (
			<div className="test">
				<PageHeader>Test Questions</PageHeader>

				{this.state.error &&
					<div>
						<div className="error">{this.state.error}</div>
						<a href="/purchase" className="btn btn-success">Purchase Credits</a>
						<a href="/" className="btn btn-default">Return to Home</a>
					</div>
				}

				{testQuestions.length>0 &&
					<QuizApp testId={testId} testData={testQuestions} />
				}
			</div>
		);
	}

	render() {
		return <div className="Test">{this.props.isAuthenticated ? this.renderAuthView() : this.renderUnauthView()}</div>;
	}
}
