import axios from "axios";
import React, { Component } from 'react';
import { Col, Form, FormGroup, FormControl, ControlLabel, Row, Grid
	, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { confirmAlert } from '../../node_modules/react-confirm-alert';
import LinkButton from '../components/LinkButton';
import config from '../config.js';

export default class Company extends Component {
	state = {
		isLoading: true,
		hasCompany: false,
		companyDataIsLoaded: false,
		userData: [],
		companyData: null,
		newMemberEmail: "",
		recipientCognito: null,
		creditsToTransfer: 0,
		creditsToPurchase: 0,
		stripeToken: "",
		toastSuccess: false,
		toastMsg: "",
		toastTime: 7000
	};

	async componentDidMount() {
		Auth.currentAuthenticatedUser().then(response=>{
			// console.log(response.signInUserSession);
			let jwt = response.signInUserSession.idToken.jwtToken;
			sessionStorage.setItem('myJwt', jwt);

			this.getUserData();
		});
	}

	// Display a notification message when an API action is taken
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		const toastHandle = document.getElementById("toast");

		if(toastHandle)
			toastHandle.scrollIntoView();

		//console.log("making toast");

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: false });
		}, this.state.toastTime);
	}


	getCompanyData() {
		//console.log("run getCompanyData");
		const token = sessionStorage.getItem('myJwt');

		const apiUrl = config.apiGateway.URL;

		const headers = {
			'Content-Type': 'application/json',
			'Authorization': token
		}

		const data = {};

		axios({
			method: 'GET',
			url: apiUrl + '/me/company',
			headers: headers,
			data: data
		})
		.then((response) => {
			//console.log("GET success");
			//console.log(response.data.data);

			this.setState({
				companyData: response.data.data,
				companyDataIsLoaded: true
			})
		})
		.catch((error) => {
			console.log("GET error");
			console.log(error.response);

			return error.response;
		});
	}


	getUserData() {
		//console.log("run getUserData");
		const token = sessionStorage.getItem('myJwt');

		const apiUrl = config.apiGateway.URL;

		const headers = {
			'Content-Type': 'application/json',
			'Authorization': token
		}

		const data = {};

		axios({
			method: 'GET',
			url: apiUrl + '/me',
			headers: headers,
			data: data
		})
		.then((response) => {
			//console.log("GET success");
			//console.log(response.data.data);
			var temp = false;

			if(response.data.data.company_name) {
				temp = true;
				this.getCompanyData();
			}

			//console.log("**");
			//console.log(response.data.data.company_name);

			this.setState({
				isLoading: false,
				userData: response.data.data,
				hasCompany: temp
			})
		})
		.catch((error) => {
			console.log("GET error");
			console.log(error.response);
			return error.response;
		});
	}


	registerCompany() {
		const apiUrl = config.apiGateway.URL;
		const token = sessionStorage.getItem('myJwt');

		const params = {
			company_name: this.state.userData.company_name.replace(" ", "%20")
		}

		const headers = {
			'Authorization': token
		}

		const request = apiUrl + "/company?company_name=" + params.company_name;

		//console.log(request);
		//console.log("**");
		//console.log(params);

		axios({
			method: 'POST',
			url: request,
			headers: headers,
			data: {}
		})
		.then((response) => {
			//console.log("POST success");
			//console.log(response);
			this.setState({ hasCompany: true });
			this.makeToast("Company registered succesfully!", true);
		})
		.catch((error) => {
			console.log("POST error");
			console.log(error);

			if(error.response.data.data.public)
				this.makeToast(`Error: ${error.response.data.data.public}, ${error.response.data.data.details}`, false);

			return error.response;
		});
	}

	inviteNewMember() {
		const apiUrl = config.apiGateway.URL;
		const token = sessionStorage.getItem('myJwt');
		const headers = {
			'Authorization': token
		}

		const request = apiUrl + "/invite?email=" + this.state.newMemberEmail;
		//console.log(request);

		axios({
			method: 'POST',
			url: request,
			headers: headers,
			data: {}
		})
		.then((response) => {
			//console.log("POST success");
			//console.log(response);

			this.makeToast("Invite sent!", true);
		})
		.catch((error) => {
			console.log("POST error");
			console.log(error);

			if(error.response)
				this.makeToast(`Error: ${error.response.data.data.public}, ${error.response.data.data.details}`, false);

			return error.response;
		});
	}

	/*
		Gets confirmation before calling the transfer credits endpoint.
	*/
	transferCredits() {
		confirmAlert({
			title: 'Confirm',
			message: `Are you sure want transfer ${this.state.creditsToTransfer} credits to user ${this.state.recipientCognito}?`,
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.postTransferCredits()
				},
				{
					label: 'No',
					onClick: () => { } //do nothing
				}
			]
		});
	}

	/*
		Make the POST call to transfer credits to another user in the company.
	*/
	postTransferCredits() {
		const apiUrl = config.apiGateway.URL;
		const token = sessionStorage.getItem('myJwt');

		const headers = {
			'Authorization': token
		}

		const count = this.state.creditsToTransfer;
		const id = this.state.recipientCognito;

		const request = apiUrl + `/credits/${count}/user/${id}`;

		console.log(request);

		//console.log("**");
		//console.log(params);

		axios({
			method: 'POST',
			url: request,
			headers: headers,
			data: {}
		})
		.then((response) => {
			console.log("POST success");
			console.log(response);

			this.makeToast("Credits transferred succesfully!", true);
		})
		.catch((error) => {
			console.log("POST error");
			console.log(error);

			if(error.response)
				this.makeToast(`Error: ${error.response.data.data.public}, ${error.response.data.data.details}`, false);

			return error.response;
		});
	}


	sendInvite() {
		if(this.validateEmail(this.state.newMemberEmail)) {
			this.inviteNewMember();
		}
	}

	validateEmail(mail) {
		if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail))
		{
			return (true)
		}

		this.makeToast("You have entered an invalid email address!", false);

		return false;
	}


	/*
		Builds the company information component for users that have an existing company.
	*/
	buildCompanyProfile() {
		const data = this.state.companyData;
		//console.log(data);

		// if company data is not empty
		if(data && this.state.companyDataIsLoaded) {

			// invitees, row for each email
			const invitees = Object.values(data.invitees).map((val, i) => {
				return (
					<Row key={ i }>
						<Col sm={2}></Col>
						<Col sm={4} className="invitedEmail">
							{ val }
						</Col>
					</Row>
				);
			});

			const tooltip = (
				<Tooltip id="tooltip">
					<strong>Click to transfer credits</strong>
				</Tooltip>
			);

			const purchaseCredits = this.buildPurchaseCredits();

			// one row for each member
			// Warning: Each child in a list should have a unique "key" prop.
			const members = data.members.map((val, i) => {
				return (
					<OverlayTrigger placement="top" overlay={ tooltip }>
						<Col className="memberCard" sm={10} onClick={ (e) => {
								this.showTransferForm(val.cognito_sub);
							} }>
							<Row>
								<Col sm={3} className="memberListLabel">
									Cognito ID
								</Col>
								<Col sm={8}>
									{ val.cognito_sub }
								</Col>
							</Row>
							<Row>
								<Col sm={3} className="memberListLabel">
									Given Name
								</Col>
								<Col sm={8}>
									{ val.given_name }
								</Col>
							</Row>
							<Row>
								<Col sm={3} className="memberListLabel">
									Family Name
								</Col>
								<Col sm={8}>
									{ val.family_name }
								</Col>
							</Row>
							<Row>
								<Col sm={3} className="memberListLabel">
									Email
								</Col>
								<Col sm={8}>
									{ val.email }
								</Col>
							</Row>
							<Row>
								<Col sm={3} className="memberListLabel">
									Credits
								</Col>
								<Col sm={8}>
									{ val.credits }
								</Col>
							</Row>
						</Col>
					</OverlayTrigger>
				);
			});

			return (
				<Grid>
					<Row>
						<Col style={{'padding':'1.5rem'}}><h3>Company Information</h3></Col>
					</Row>
					{/*<Row>
						<FormGroup>
							<Col componentClass={ControlLabel} sm={2}>
								Company ID
							</Col>
							<Col sm={10}>
								<FormControl type="text" placeholder="" value={ data.company_id || ''} disabled />
							</Col>
						</FormGroup>
					</Row>*/}
					<Row>
						<FormGroup>
							<Col componentClass={ControlLabel} sm={2}>
								Company Name
							</Col>
							<Col sm={10}>
								<FormControl type="text" placeholder="" value={ data.company_name || ''} disabled />
							</Col>
						</FormGroup>
					</Row>
					<Row>
						<Col className="smallSpacer">

						</Col>
					</Row>
					{
						purchaseCredits
					}
					<Row>
						<Col className="spacer">
						</Col>
					</Row>
					<Row>
						<Col sm={2}></Col>
						<Col className="mt-1" sm={6}>
							<h4>Invited Members</h4>
						</Col>
					</Row>
					{
						invitees
					}
					<Row>
						<Col className="smallSpacer"></Col>
					</Row>
					<Row>
						<Col sm={2}></Col>
						<Col sm={10}>
							<h4 className="m-1">Member List</h4>
						</Col>
					</Row>
					<Row>
						<Col className="smallSpacer"></Col>
					</Row>
					<Row>
						<Col sm={2}></Col>
						<Col sm={6} className="memberListLabel">
							Invite New Member
						</Col>
					</Row>
					<Row>
						<Col sm={2}></Col>
						<Col sm={5}>
							<FormControl className="mb-1" type="text" placeholder="Enter email address for invite here..." value={ this.state.newMemberEmail || ''}
								onChange={ (e) => {
									this.setState({ newMemberEmail: e.target.value });
								} } />
						</Col>
						<Col sm={5}>
							<button type="button" className="smallButtonStyle btn-success btn-sm" onClick={ () => { this.sendInvite() } }>Send invite</button>
						</Col>
					</Row>
					<Row>
						<Col sm={2}></Col>
						<Col sm={10}>
							<Row>
							{
								members
							}
							</Row>
						</Col>
					</Row>
					<Row>
						<Col className="spacer">

						</Col>
					</Row>
				</Grid>
			);
		} else {
			return (
				<span value={ this.state.companyDataIsLoaded }>""</span>
			);
		}
	}


	/*
		Display the transfer credits form with target user.
	*/
	showTransferForm(sub) {
		this.setState({ recipientCognito: sub, creditsToTransfer: 0 });
		const temp = document.getElementById("transfer-credit-stub");

		if(temp)
			temp.scrollIntoView();
	}


	/*
		Builds the transfer credits form component.
	*/
	buildTransferCredits() {
		const visClass = (this.state.recipientCognito) ? "" : "hidden";

		return (
			<Grid className={ visClass }>
				<Row>
					<Col sm={1}></Col>
					<Col sm={6}><h4 className="sectionHeading">Transfer Credits</h4></Col>
				</Row>
				<FormGroup className="mt-1">
					<Row>
						<Col componentClass={ControlLabel} sm={2}>
							User ID
						</Col>
						<Col sm={8}>
							<FormControl type="text" placeholder="" value={this.state.recipientCognito || ''}
								onChange={ (e) => {
									this.setState({ recipientCognito: e.target.value });
								} } />
						</Col>
					</Row>
				</FormGroup>
				<FormGroup className="mt-1">
					<Row>
						<Col componentClass={ControlLabel} sm={2}>
							Number of credits
						</Col>
						<Col sm={4}>
							<FormControl type="text" placeholder="" value={this.state.creditsToTransfer || ''}
								onChange={ (e) => {
									this.setState({ creditsToTransfer: e.target.value });
								} } />
						</Col>
					</Row>
					<Row>
						<Col sm={2}></Col>
						<Col className="btnCol" sm={3}>
							<button type="button" className="btn-success btn-sm" onClick={ () => { this.transferCredits() } }>Transfer Credits</button>
						</Col>
					</Row>
				</FormGroup>
			</Grid>
		);
	}

	/*
		Builds the purchase credits component.
	*/
	buildPurchaseCredits() {
		return (
			<Row>
				<FormGroup>
					<Col componentClass={ControlLabel} sm={2}>
						Credits
					</Col>
					<Col sm={8}>
						<a className="btn btn-success" href="/purchase">Purchase Credits</a>
					</Col>
				</FormGroup>
			</Row>
		);
	}

	/*
		Builds the new company registration component.
	*/
	buildNewCompany() {
		return (
			<Grid>
				<Row>
					<Col><h3 className="sectionHeading">Register New Company</h3></Col>
				</Row>
				<FormGroup className="mt-1">
					<Row>
						<Col componentClass={ControlLabel} sm={2}>
							Company Name
						</Col>
						<Col sm={8}>
							<FormControl type="text" placeholder="" value={this.state.userData.company_name || ''}
								onChange={ (e) => {
									const temp = this.state.userData;
									temp.company_name = e.target.value;
									this.setState({ userData: temp });
								} } />
						</Col>
					</Row>
					<Row>
						<Col sm={2}></Col>
						<Col className="btnCol" sm={3}>
							<button type="button" className="btn-success btn-sm" onClick={ () => { this.registerCompany() } }>Submit Registration</button>
						</Col>
					</Row>
				</FormGroup>
			</Grid>
		);
	}

	render() {
		const userData = this.state.userData || {};

		//open the refresher course pdf in the current window
		const openRefresher = function(e) { e.preventDefault(); window.open("refresher-course.pdf", "_blank"); };

		var company = (this.state.hasCompany) ? this.buildCompanyProfile() : this.buildNewCompany();

		var testCards = null;

		const transfer = this.buildTransferCredits();

		//console.log("rendering");
		//console.log(userData);

		var toastClass = "hidden";
		if(this.state.toastMsg !== "")
			toastClass = (this.state.toastSuccess) ? "bg-success" : "bg-failure";

		//console.log("*");
		//console.log(toastClass);

		return (this.state.isLoading) ? (
			<div className="loadingMessage">Loading...</div>
		) : (
			<div>
				<Row>
					<Col sm={3}></Col>
					<Col sm={6}>
						<div id="toast" className={ toastClass }>{ this.state.toastMsg }</div>
					</Col>
				</Row>
				<Row>
					{
						company
					}
					{
						transfer
					}
					<div id="transfer-credit-stub"></div>
				</Row>
				<Row>
					<Col className="text-right">
						<a href="/" className="btn btn-lg btn-info">Return to Main</a>
					</Col>
				</Row>
			</div>
		);
	}
}
