export default {
	apiGateway: {
		REGION: 'us-east-1',
		URL: 'https://agoxhh63vk.execute-api.us-east-1.amazonaws.com/dev'
	},
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_pVodnhQ2J',
		APP_CLIENT_ID: '5nnuqpe1dlfm7320f52209emir',
		IDENTITY_POOL_ID: 'YOUR_IDENTITY_POOL_ID'
	}
};