import React, {useReducer} from 'react';
import { Table } from 'react-bootstrap';
// import { Auth } from 'aws-amplify';
import axios from "axios";

import Progress from './Progress';
import Question from './Question';
import Answers from './Answers';
import QuizContext from '../context/QuizContext';
import config from '../config';

import {
	SET_ANSWERS,
	SET_CURRENT_QUESTION,
	SET_CURRENT_ANSWER,
	SET_ERROR,
	SET_SHOW_RESULTS,
	SET_RESULTS,
	RESET_QUIZ,
} from '../reducers/types.js';
import quizReducer from '../reducers/QuizReducer';

function QuizApp(props) {
	// console.log("props", props);

	const questions = props.testData;
	let isLoading = false;
	// let results = null;

	const initialState = {
		questions,
		currentQuestion: 0,
		currentAnswer: '',
		answers: [],
		showResults: false,
		error: '',
		results: []
	};

	const [state, dispatch] = useReducer(quizReducer, initialState);
	const {currentQuestion, currentAnswer, answers, showResults, error, results} = state;

	const question = questions[currentQuestion];

	const renderError = () => {
		if (!error) {
			return;
		}

		return <div className="error">{error}</div>;
	};

	/*const renderResultMark = (question, answer) => {
		if (question.correct_answer === answer.answer) {
			return <span className="correct">Correct</span>;
		}

		return <span className="failed">Failed</span>;
	};*/

	const renderResultsData = () => {
		// OUTDATED - NEEDS TO BE REWRITTEN
		/*return answers.map(answer => {
			const question = questions.find(
				question => question.id === answer.questionId
			);

			return (
				<div key={question.id}>
					{question.question} - {renderResultMark(question, answer)}
				</div>
			);
		});*/

		//console.log("renderResultsData results", results);

		return (
			<div>
				{/*<p>{JSON.stringify(results)}</p>*/}
				<Table striped bordered condensed hover>
					<tbody>
						<tr>
							<td width="25%"><b>Total Questions</b></td>
							<td>{results.number_questions}</td>
						</tr>
						<tr>
							<td width="25%"><b>Correct Answers</b></td>
							<td>{results.correct_count}</td>
						</tr>
						<tr>
							<td width="25%"><b>Incorrect Answers</b></td>
							<td>{results.incorrect_count}</td>
						</tr>
						<tr>
							<td width="25%"><b>Score</b></td>
							<td>{results.percent}%</td>
						</tr>
						<tr>
							<td width="25%"><big><b>STATUS</b></big></td>
							<td><big>{results.status.toUpperCase()}</big></td>
						</tr>
					</tbody>
				</Table>
			</div>
		);
	};

	const restart = () => {
		dispatch({type: RESET_QUIZ});
	};

	const submitAnswer = () => {
		//console.log("run submitAnswer");

		isLoading = true;
		//console.log("isLoading", isLoading);
		const answer = {questionId: question.id, answer: currentAnswer};
		// console.log("answer", answer);

		if (!currentAnswer) {
			dispatch({type: SET_ERROR, error: 'Please select an option.'});
			return;
		}

		answers.push(answer);
		dispatch({type: SET_ANSWERS, answers});
		dispatch({type: SET_CURRENT_ANSWER, currentAnswer: ''});

		// authenticate then check answer
		/*Auth.currentAuthenticatedUser().then(response=>{
			let jwt = response.signInUserSession.idToken.jwtToken;
			sessionStorage.setItem('myJwt', jwt);

			getAnswerResult(answer);
		});*/

		getAnswerResult(answer);
	}

	const getAnswerResult = (answer) => {
		//console.log("run getAnswerResult");
		// console.log("getAnswerResult question", question);
		const testId = props.testId;

		const token = sessionStorage.getItem('myJwt');
		const apiUrl = config.apiGateway.URL;

		const headers = {
			'Content-Type': 'application/json',
			'Authorization': token
		}
		const data = {};

		// REWRITE THIS LATER to use hateoas links
		axios({
			method: 'POST',
			url: apiUrl + '/test/'+testId+'/question/'+question.questionId+'/option/'+question["answerId_"+answer.answer],
			headers: headers,
			data: data
		})
		.then((response) => {
			//console.log("getAnswerResult POST success");
			//console.log(response.data.data);

			// results = response.data.data;
			// console.log("getAnswerResult results", results);
			dispatch({type: SET_RESULTS, results: response.data.data});

			/*let responseData = response.data.data;
			if (responseData.is_correct) {
				getNextQuestion();
			} else {
				dispatch({type: SET_ERROR, error: 'Your answer is incorrect.'});
				return;
			}*/

			getNextQuestion();
		})
		.catch((error) => {
			//console.log("getAnswerResult POST error");
			//console.log(error);
			
			if(error.response) {
				console.log(error.response.data);
				dispatch({type: SET_ERROR, error: error.response.data.data.public});
			}
			
			return;
		});
	}

	const getNextQuestion = () => {
		//console.log("run getNextQuestion");

		isLoading = false;
		//console.log("isLoading", isLoading);

		// if this is not the last question, continue
		if (currentQuestion + 1 < questions.length) {
			dispatch({
				type: SET_CURRENT_QUESTION,
				currentQuestion: currentQuestion + 1,
			});
			return;
		}

		// if this is the last question, show results - OUTDATED
		dispatch({type: SET_SHOW_RESULTS, showResults: true});
	}

	if (showResults) {
		return (
			<div className="container results">
				<h2>Results</h2>
				<ul>{renderResultsData()}</ul>
				<button className="btn btn-primary" onClick={restart}>
					Restart
				</button>
			</div>
		);
	} else {
		return (
			<QuizContext.Provider value={{state, dispatch}}>
				<div className="container">
					<Progress
						total={questions.length}
						current={currentQuestion + 1}
					/>
					<Question />
					{renderError()}
					<Answers />
					<div className="submit-button">
						<button className="btn btn-primary" onClick={!isLoading ? submitAnswer : null} disabled={isLoading}>
							{isLoading ? 'Loading...' : 'Confirm and Continue'}
						</button>
					</div>
				</div>
			</QuizContext.Provider>
		);
	}
}

export default QuizApp;