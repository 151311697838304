import React, {useContext} from 'react';
import Answer from './Answer';
import QuizContext from '../context/QuizContext';

function Answers() {
	const {state, dispatch} = useContext(QuizContext);
	const {currentAnswer, currentQuestion, questions} = state;
	const question = questions[currentQuestion];

	return (
		<div>
			{ question.answer_1 &&
				<Answer
					letter="1"
					answer={question.answer_1}
					dispatch={dispatch}
					selected={currentAnswer === "1"}
				/>
			}
			{ question.answer_2 &&
				<Answer
					letter="2"
					answer={question.answer_2}
					dispatch={dispatch}
					selected={currentAnswer === "2"}
				/>
			}
			{ question.answer_3 &&
				<Answer
					letter="3"
					answer={question.answer_3}
					dispatch={dispatch}
					selected={currentAnswer === "3"}
				/>
			}
			{ question.answer_4 &&
				<Answer
					letter="4"
					answer={question.answer_4}
					dispatch={dispatch}
					selected={currentAnswer === "4"}
				/>
			}
			{ question.answer_5 &&
				<Answer
					letter="5"
					answer={question.answer_5}
					dispatch={dispatch}
					selected={currentAnswer === "5"}
				/>
			}
			{ question.answer_6 &&
				<Answer
					letter="6"
					answer={question.answer_6}
					dispatch={dispatch}
					selected={currentAnswer === "6"}
				/>
			}
		</div>
	);
}

export default Answers;