import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Me from './Me.js';

export default class Home extends Component {

	async componentDidMount() {
		Auth.currentAuthenticatedUser().then(response=>{
			// console.log(response.signInUserSession);
			let jwt = response.signInUserSession.idToken.jwtToken;
			sessionStorage.setItem('myJwt', jwt);
		});
	}

	renderUnauthView() {
		this.props.userHasAuthenticated(false);
		this.props.history.push('/login');

		/*return (
			<div className="lander">
				<h1>Unauthorized</h1>
				<p>Please <a href="/">log in</a> to access this page.</p>
			</div>
		);*/
	}

	renderAuthView() {
		return (
			<Me/>
		);
	}

	render() {
		return <div className="Home">{this.props.isAuthenticated ? this.renderAuthView() : this.renderUnauthView()}</div>;
	}
}
