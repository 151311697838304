import React, { Component } from 'react';
import { PageHeader } from 'react-bootstrap';

import CheckoutForm from '../components/CheckoutForm';

export default class PurchaseCredit extends Component {
	state = {
		isLoading: true
	};

	renderUnauthView() {
		return (
			<div className="lander">
				<h1>Unauthorized</h1>
				<p>Please <a href="/login">log in</a> to access this page.</p>
			</div>
		);
	}

	renderAuthView() {
		return (
			<div className="PurchaseCredit">
				<PageHeader>Purchase Credits</PageHeader>
				<p>Taking the drilling test requires one test credit. Each test credit is <b>$30.00</b>.</p>
				<CheckoutForm />
			</div>
		);
	}

	render() {
		return <div>{this.props.isAuthenticated ? this.renderAuthView() : this.renderUnauthView()}</div>;
	}
}
