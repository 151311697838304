import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppliedRoute from './components/AppliedRoute';

import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import ResetPassword from './containers/ResetPassword';
import Signup from './containers/Signup';
import Me from './containers/Me';
import Company from './containers/Company';
import TestHistory from './containers/TestHistory';
import Test from './containers/Test';
import PurchaseCredit from './containers/PurchaseCredit';

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute path="/" exact component={Home} props={childProps} />
		<AppliedRoute path="/login" exact component={Login} props={childProps} />
		<AppliedRoute path="/resetpassword" exact component={ResetPassword} props={childProps} />
		<AppliedRoute path="/signup" exact component={Signup} props={childProps} />
		<AppliedRoute path="/me" exact component={Me} props={childProps} />
		<AppliedRoute path="/company" exact component={Company} props={childProps} />
		<AppliedRoute path="/testhistory" exact component={TestHistory} props={childProps} />
		<AppliedRoute path="/test" exact component={Test} props={childProps} />
		<AppliedRoute path="/purchase" exact component={PurchaseCredit} props={childProps} />
		{/* Finally, catch all unmatched routes */}
		<Route component={NotFound} />
	</Switch>
);
