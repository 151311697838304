import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { Auth } from 'aws-amplify';

export default class Login extends Component {
	state = {
		isLoading: false,
		username: '',
		password: ''
	};

	validateForm() {
		return this.state.username.length > 0 && this.state.password.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		try {
			await Auth.signIn(this.state.username, this.state.password);
			this.props.userHasAuthenticated(true);
			this.props.history.push('/');
		} catch (e) {
			alert(e.message);
			this.setState({ isLoading: false });
		}
	};

	render() {
		return (
			<div className="Login">
				<div className="text-center" style={{'padding-bottom':'2rem'}}>
					<p>To access the Optimization Training Testing, enter the login/password sent to your email or click Register Now.</p>
				</div>
				<form onSubmit={this.handleSubmit}>
					<FormGroup controlId="username" bsSize="large">
						<ControlLabel>Username</ControlLabel>
						<FormControl autoFocus type="text" value={this.state.username} onChange={this.handleChange} />
					</FormGroup>
					<FormGroup controlId="password" bsSize="large">
						<ControlLabel>Password</ControlLabel>
						<FormControl value={this.state.password} onChange={this.handleChange} type="password" />
					</FormGroup>
					<LoaderButton
						block
						bsSize="large"
						disabled={!this.validateForm()}
						type="submit"
						isLoading={this.state.isLoading}
						text="Login"
						loadingText="Logging in…"
					/>
					<LinkContainer to="/signup">
						<a href="/signup" className="btn btn-block btn-lg btn-info">Register Now</a>
					</LinkContainer>
					<div className="passwordFieldSubtext" style={{'padding':'1rem'}}>
						<LinkContainer to="/resetpassword">
							<span>Forgot password?</span>
						</LinkContainer>
					</div>
				</form>
			</div>
		);
	}
}
