import React, {useContext} from 'react';
import QuizContext from '../context/QuizContext';

function Question() {
    const {state} = useContext(QuizContext);
    const {currentQuestion, questions} = state;
    const question = questions[currentQuestion];
    // console.log("Question.js question", question);
    return (
    	<div className="question">
	    	{question.imageUrl &&
	    		<div className="question-image"><img src={question.imageUrl} alt={question.id} /></div>
            }
	    	<h2 dangerouslySetInnerHTML={{ __html: question.question }} />
	    </div>
    );
}

export default Question;