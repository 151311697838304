import React from 'react'
import { Grid, Row, Col, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import axios from "axios";
import StripeCheckout from 'react-stripe-checkout';
import config from '../config.js';

export default class CheckoutForm extends React.Component {
	state = {
		creditsToPurchase: 1,
		purchaseTotal: 0,
		creditPrice: 3000,
		toastSuccess: false,
		toastMsg: "",
		toastTime: 50000
	};

	// Display a notification message when an API action is taken
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		const toastHandle = document.getElementById("toast");

		if(toastHandle)
			toastHandle.scrollIntoView();

		console.log("making toast");

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: false });
		}, this.state.toastTime);
	}

	/*
		Make a POST call to finalize the purchase of credits using a Stripe token.
	*/
	postPurchase = (token, addresses) => {
		console.log(token, addresses);

		const apiUrl = config.apiGateway.URL;
		const authToken = sessionStorage.getItem('myJwt');

		const headers = {
			'Authorization': authToken
		}

		const count = this.state.creditsToPurchase;
		const stripeToken = token.id;

		const request = apiUrl + `/credits/${count}/stripe/${stripeToken}`;

		console.log(request);

		//console.log("**");
		//console.log(params);

		axios({
			method: 'POST',
			url: request,
			headers: headers,
			data: {}
		})
		.then((response) => {
			console.log("POST success");
			console.log(response);

			this.makeToast("Credits purchased successfully!", true);
		})
		.catch((error) => {
			console.log("POST error");
			console.log(error.response);

			if(error.response)
				this.makeToast(`Error: ${error.response.data.data.public}, ${error.response.data.data.details}`, false);

			return error.response;
		});
	};

	changeTotal = (e) => {
		this.setState({ creditsToPurchase: e.target.value, purchaseTotal: e.target.value * this.state.creditPrice });
		console.log(this.state);
	};

	render() {

		var toastClass = "hidden";
		if(this.state.toastMsg !== "") {
			toastClass = (this.state.toastSuccess) ? "bg-success" : "bg-failure";
		}

		return (
			<Grid>
				<FormGroup className="mt-1">
					<Row>

						<FormGroup controlId="formControlsSelect">
							<Col sm={2}>
								<ControlLabel>Number of Credits</ControlLabel>
							</Col>
							<Col sm={2}>
								<FormControl componentClass="select" placeholder="select" value={this.state.creditsToPurchase} onChange={this.changeTotal}>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">9</option>
									<option value="10">10</option>
								</FormControl>
							</Col>
							<Col sm={3}>
								<div className="checkout-form">
									<StripeCheckout
										label="Purchase"
										stripeKey="pk_test_yzMGmO7GzS6Q8e6FDtDMzVAM00KutF7pMm"
										token={this.postPurchase}
										amount={this.state.purchaseTotal}
										currency="CAD"
										billingAddress
										description="Drilling Test Credits"
										locale="auto"
										name="Directional Drilling"
										zipCode>
									</StripeCheckout>
								</div>
							</Col>
						</FormGroup>

					</Row>
					<Row>
						<Col sm={2}></Col>
						<Col sm={6}>
							<div id="toast" className={ toastClass }>{ this.state.toastMsg }</div>
						</Col>
					</Row>
				</FormGroup>
			</Grid>
		)
	}
}